import { Injectable, inject } from '@angular/core';
import { Langauge } from '@enums/language.enum';
import { JWT } from '@interfaces/jwt.interface';
import { User } from '@models/user.model';
import { PlatformService } from './platform.service';
import { UserFlag } from '@enums/user-flag.enum';

/**
 * Logged in user storage key name
 */
const USER_KEY = 'user';
/**
 * Key for storing guest user data in storage.
 */
const GUEST_USER_KEY = 'guest-user';
/**
 * Logged in user JWT token key name
 */
const ACCESS_TOKEN_KEY = 'access-token';
/**
 * Refresh token key name
 */
const REFRESH_TOKEN_KEY = 'refresh-token';
/**
 * Current address key name
 */
const CURRENT_ADDRESS_KEY = 'current-address';
/**
 * Current address key name
 */
const TIMER_TIMESTAMP = 'timer-timestamp';
/**
 * Current language key name
 */
export const LANGUAGE_KEY = 'language';
/**
 * Key for storing the user to verify after sign up in the storage service.
 */
export const USER_TO_VERIFY_AFTER_SIGN_UP_KEY = 'user-to-verify';

/**
 * Service to manage storage data
 */
@Injectable({ providedIn: 'root' })
export class StorageService {
  private readonly platform = inject(PlatformService);

  /**
   * Set JWT and Refrersh token
   */
  public saveJwt(data: JWT): void {
    this.removeAcessToken();
    this.removeRefreshToken();
    this.setItem(ACCESS_TOKEN_KEY, data.access_token);
    this.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
  }

  /**
   * Removes the access token from the storage.
   */
  public removeAcessToken(): void {
    this.deleteItem(ACCESS_TOKEN_KEY);
  }

  /**
   * Removes the refresh token from the storage.
   */
  public removeRefreshToken(): void {
    this.deleteItem(REFRESH_TOKEN_KEY);
  }

  /**
   * Get JWT token if exists
   */
  public getAccessToken(): string | null {
    return this.getItem(ACCESS_TOKEN_KEY);
  }

  /**
   * Get Refresh token if exists
   */
  public getRefreshToken(): string | null {
    return this.getItem(REFRESH_TOKEN_KEY);
  }

  /**
   * Save logged in user data
   */
  public saveUser(user: User): void {
    if (this.platform.isPlatformBrowser()) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  }

  /**
   * Get logged in user data
   */
  public getUser(): User | null {
    if (this.platform.isPlatformBrowser()) {
      const user = localStorage.getItem(USER_KEY);
      return user ? JSON.parse(user) : null;
    }
    return null;
  }

  /**
   * Saves the guest user to the storage.
   * @param user - The user object to be saved.
   */
  public saveGuestUser(user: User): void {
    if (this.platform.isPlatformBrowser()) {
      localStorage.setItem(GUEST_USER_KEY, JSON.stringify(user));
    }
  }

  /**
   * Get logged in user data
   */
  public getGuestUser(): User | null {
    if (this.platform.isPlatformBrowser()) {
      const user = localStorage.getItem(GUEST_USER_KEY);
      return user ? JSON.parse(user) : null;
    }
    return null;
  }

  /**
   * Removes the guest user from storage.
   */
  public removeGuestUser(): void {
    if (this.platform.isPlatformBrowser()) {
      localStorage.removeItem(GUEST_USER_KEY);
    }
  }

  /**
   * Get user activated status
   */
  public isUserActivated(): boolean {
    const user = this.getUser();
    return user ? user.activated === 1 && !!user.phone : false;
  }

  public phoneNumberAlreadySet(): boolean {
    const user = this.getUser();
    if (!user) {
      return false;
    }
    return !!user.phone;
  }

  public isTesterUser(): boolean {
    const user = this.getUser();
    if (!user) {
      return false;
    }
    return user.isTester === UserFlag.TESTER_USER;
  }

  /**
   * Check if user is exists and is logged in
   */
  public isLoggedIn(): boolean {
    return !!this.getUser();
  }

  /**
   * Set language
   */
  public setLanguage(language: Langauge): void {
    this.setItem(LANGUAGE_KEY, language);
  }

  /**
   * Get language
   */
  public getLanguage(): string | null {
    return this.getItem(LANGUAGE_KEY);
  }

  /**
   * Set timer timestamp
   */
  public setTimerTimestamp(timestamp: string): void {
    this.setItem(TIMER_TIMESTAMP, timestamp);
  }

  /**
   * Get timestamp
   */
  public getTimerTimestamp(): string | null {
    return this.getItem(TIMER_TIMESTAMP);
  }

  /**
   * Sets the user to verify after sign up.
   * @param userId - The ID of the user to verify.
   */
  public setUserToVerify(userId: string): void {
    this.setItem(USER_TO_VERIFY_AFTER_SIGN_UP_KEY, userId);
  }

  /**
   * Retrieves the user to verify after sign up from the storage.
   * @returns The user to verify as a string, or null if not found.
   */
  public getUserToVerify(): string | null {
    return this.getItem(USER_TO_VERIFY_AFTER_SIGN_UP_KEY);
  }

  /**
   * Clears the user to verify after sign up.
   */
  public clearUserToVerify(): void {
    this.deleteItem(USER_TO_VERIFY_AFTER_SIGN_UP_KEY);
  }

  /**
   * Get language
   */
  public clearTimestamp(): void {
    this.deleteItem(TIMER_TIMESTAMP);
  }

  /**
   * Set current address
   */
  public setCurrentAdress(address: string): void {
    this.setItem(CURRENT_ADDRESS_KEY, address);
  }

  /**
   * Get current address
   */
  public getCurrentAddress(): string | null {
    return this.getItem(CURRENT_ADDRESS_KEY);
  }

  /**
   * Retrieves the value associated with the specified key from the local storage.
   *
   * @param key - The key of the item to retrieve.
   * @returns The value associated with the specified key, or `null` if the key does not exist or if the platform is not a browser.
   */
  private getItem(key: string): string | null {
    if (this.platform.isPlatformBrowser()) {
      return localStorage.getItem(key);
    }
    return null;
  }

  /**
   * Sets the value for the specified key in the local storage.
   *
   * @param key - The key to set the value for.
   * @param value - The value to be set.
   */
  private setItem(key: string, value: any): void {
    if (this.platform.isPlatformBrowser()) {
      localStorage.setItem(key, value);
    }
  }

  /**
   * Deletes an item from the local storage.
   *
   * @param key - The key of the item to delete.
   */
  private deleteItem(key: string): void {
    if (this.platform.isPlatformBrowser()) {
      localStorage.removeItem(key);
    }
  }

  /**
   * Clear data
   */
  public clean(): void {
    const itemsToRemove: string[] = [
      ACCESS_TOKEN_KEY,
      REFRESH_TOKEN_KEY,
      USER_KEY,
      GUEST_USER_KEY,
      CURRENT_ADDRESS_KEY,
    ];

    if (this.platform.isPlatformBrowser()) {
      itemsToRemove.forEach((key: string) => this.deleteItem(key));
    }
  }
}
